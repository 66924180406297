globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"4c42QiGA0MZVbu_Sys9zl"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
/*
| Developed by Dirupt
| Filename : sentry.client.config.ts
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init, replayIntegration } from '@sentry/nextjs'

init({
	enabled: process.env.NODE_ENV === 'production',

	// This is the release version of your application
	environment: process.env.NEXT_PUBLIC_APP_ENV,

	dsn: 'https://c94b5f683f16b8b5983219d094ededf3@o4506670738571264.ingest.us.sentry.io/4506670739750913',

	// This sets the depth of the stack trace.
	normalizeDepth: 10,

	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 1,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: false,
	replaysOnErrorSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// You can remove this option if you're not planning to use the Sentry Session Replay feature:
	integrations: [
		replayIntegration({
			// Additional Replay configuration goes in here, for example:
			maskAllText: true,
			blockAllMedia: false,
			maskAllInputs: true,
			networkCaptureBodies: true,
			mask: ['[data-senskle-privacy]'],
			block: ['[data-senskle-privacy-full]'],
			ignore: ['[data-senskle-privacy-ignore]'],
		}),
	],
})
